import "./scss/style.scss";
import 'bootstrap';
import { tns } from "../node_modules/tiny-slider/src/tiny-slider";
import { appear } from "../node_modules/jquery.appear/jquery.appear";

$('header').load("header.html");
$('footer').load("footer.html");

/*Script scroll header*/
$(window).on("scroll", function (e) {

    if (parseInt(Math.round($(window).scrollTop())) > 0) {
        $('header').addClass('header-scroll');
    } else {
        $('header').removeClass('header-scroll');
    }
});

if ($.contains(document.body, document.getElementById('slider-main'))) {

    var slider_main = tns({
        container: '#slider-main',
        items: 1,
        loop: true,
        slideBy: 'page',
        autoplay: true,
        speed: 1000,
        autoplayHoverPause: true,
        autoplayButtonOutput: false,
        controls: false,
        nav: false,
        lazyload: true,
        mouseDrag: true,
    });

    $(window).on("load resize scroll", function(e) {
        $('.block-banner-main .slider-main .items').each(function(index, element) {
            if ($(window).width() > 900) {
                if ($(window).scrollTop() > 30) {
                    var ratio = Math.round((($(window).scrollTop() - $(this).offset().top) / $(this).height()) * 100);
                    $(this).css('background-position', 'center ' + parseInt(-(ratio * 1.5)) + 'px');
                }
            } else {
                $(this).css('background-position', 'center top');
            }
        });
    });
}

if ($.contains(document.body, document.getElementById('slider-internal'))) {

    var slider_internal = tns({
        container: '#slider-internal',
        items: 1,
        navContainer: "#customize-thumbnails",
        navAsThumbnails: true,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayButtonOutput: false,
        swipeAngle: false,
        controls: false,
    });

    document.querySelector('.btn-slider-int.btn-left').onclick = function() {
        slider_internal.goTo('prev');
    };

    document.querySelector('.btn-slider-int.btn-right').onclick = function() {
        slider_internal.goTo('next');
    };
}
